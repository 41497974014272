/**
 * TODO: 4.0
 *
 * - Remove `dataSource`
 * - `size` not work with customizeInput
 * - CustomizeInput not feedback `ENTER` key since accessibility enhancement
 */
import * as React from 'react';
import toArray from 'rc-util/lib/Children/toArray';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import Select from '../select';
import { ConfigConsumer } from '../config-provider';
import devWarning from '../_util/devWarning';
import { isValidElement } from '../_util/reactNode';
const { Option } = Select;
function isSelectOptionOrSelectOptGroup(child) {
    return child && child.type && (child.type.isSelectOption || child.type.isSelectOptGroup);
}
const AutoComplete = (props, ref) => {
    const { prefixCls: customizePrefixCls, className, children, dataSource } = props;
    const childNodes = toArray(children);
    // ============================= Input =============================
    let customizeInput;
    if (childNodes.length === 1 &&
        isValidElement(childNodes[0]) &&
        !isSelectOptionOrSelectOptGroup(childNodes[0])) {
        [customizeInput] = childNodes;
    }
    const getInputElement = customizeInput ? () => customizeInput : undefined;
    // ============================ Options ============================
    let optionChildren;
    // [Legacy] convert `children` or `dataSource` into option children
    if (childNodes.length && isSelectOptionOrSelectOptGroup(childNodes[0])) {
        optionChildren = children;
    }
    else {
        optionChildren = dataSource
            ? dataSource.map(item => {
                if (isValidElement(item)) {
                    return item;
                }
                switch (typeof item) {
                    case 'string':
                        return (<Option key={item} value={item}>
                  {item}
                </Option>);
                    case 'object': {
                        const { value: optionValue } = item;
                        return (<Option key={optionValue} value={optionValue}>
                  {item.text}
                </Option>);
                    }
                    default:
                        throw new Error('AutoComplete[dataSource] only supports type `string[] | Object[]`.');
                }
            })
            : [];
    }
    // ============================ Warning ============================
    React.useEffect(() => {
        if (process.env.NODE_ENV !== 'production')
            devWarning(!('dataSource' in props), 'AutoComplete', '`dataSource` is deprecated, please use `options` instead.');
        devWarning(!customizeInput || !('size' in props), 'AutoComplete', 'You need to control style self instead of setting `size` when using customize input.');
    }, []);
    return (<ConfigConsumer>
      {({ getPrefixCls }) => {
        const prefixCls = getPrefixCls('select', customizePrefixCls);
        return (<Select ref={ref} {...omit(props, ['dataSource'])} prefixCls={prefixCls} className={classNames(`${prefixCls}-auto-complete`, className)} mode={Select.SECRET_COMBOBOX_MODE_DO_NOT_USE} {...{
            // Internal api
            getInputElement,
        }}>
            {optionChildren}
          </Select>);
    }}
    </ConfigConsumer>);
};
const RefAutoComplete = React.forwardRef(AutoComplete);
RefAutoComplete.Option = Option;
export default RefAutoComplete;
